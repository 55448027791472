import React from "react"
import { WindowLocation } from "@reach/router"
import TopHeader from "./utils/TopHeader"
import CookieConsent from "react-cookie-consent"
import "../components/cookieStyle.css"
import { headerMenu } from "./const"

type Props = {
  children: React.ReactNode
  location: WindowLocation<unknown>
}

const TopLayout: React.FC<Props> = ({ location, children }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div data-is-root-path={isRootPath} className={"layout-wrapper"}>
      <div className={"top-fade-in-transition"}>
        <TopHeader headerMenu={headerMenu} />
        <main>{children}</main>
      </div>
      <footer className={"footer"}>
        <div className={"footer-text-wrapper"}>
          <p className={"footer-logo"}>©</p>
          <p>2021 D Capital, Inc.</p>
        </div>
      </footer>
      <CookieConsent
        location="bottom" // cookie bannerの配置
        style={{ backgroundColor: "white" }}
        enableDeclineButton // 同意しない場合のボタン追加
        buttonText={
          <div>
            同意する
            <br />
            Accept
          </div>
        }
        declineButtonText={
          <div>
            同意しない
            <br />
            Decline
          </div>
        }
        onAccept={() => window.location.reload()} // 同意したあとの画面更新
        //classname
        buttonClasses="accept-button"
        declineButtonClasses="decline-button"
        containerClasses="cookie-contents-wrapper"
        buttonWrapperClasses="buttons-wrapper"
        contentClasses="cookie-text-wrapper"
        cookieName="gatsby-gdpr-google-analytics"
      >
        <div
          className="cookie-text"
          style={{ color: "black", textAlign: "center" }}
        >
          <div>
            本サイトは機能実現・改善のためにクッキー(Cookie)を使用します。
          </div>
          <div>By browsing this site, you agree to the use of cookies.</div>
        </div>
      </CookieConsent>
    </div>
  )
}

export default TopLayout
