import React from "react"
import { Link, PageProps } from "gatsby"
import { Query } from "../../../types/graphql-types"
import SEO from "../../components/seo"
import TopLayout from "../../components/TopLayout"
import { ja_description } from "../../components/const"

const WebPageIndex: React.FC<PageProps<Query>> = ({ location }) => {
  return (
    <div className="layout-background-color">
      <div className={"util-full-page-padding"}>
        <TopLayout location={location}>
          <SEO
            title="TOP"
            description={ja_description}
            lang={"ja"}
            twitterTitle={"D Capital"}
          />
          <div className="top-page-wrapper">
            <div className="top-banner-wrapper fade-in-bottom">
              <Link to="/">
                <svg
                  preserveAspectRatio="xMidYMid meet"
                  data-bbox="0 -0.02 250 31.84"
                  viewBox="0 -0.02 250 31.84"
                  xmlns="http://www.w3.org/2000/svg"
                  data-type="color"
                  role="presentation"
                  aria-hidden="true"
                  aria-labelledby="svgcid--f9kf87haykuy"
                >
                  <g>
                    <path
                      d="M27.73 15.56C27.91 23.25 22.84 31 11.94 31H0V.79h11.94c10.55 0 15.62 7.34 15.79 14.77zM2.4 3v25.78h9.54c9.26 0 13.53-6.74 13.39-13.22C25.2 9.26 20.93 3 11.94 3z"
                      fill="#404041"
                      data-color="1"
                    />
                    <path
                      d="M65.67 31.82A15.92 15.92 0 1 1 76.93 4.65l-1.67 1.67a13.56 13.56 0 1 0 0 19.17l1.67 1.67a15.89 15.89 0 0 1-11.26 4.66z"
                      fill="#404041"
                      data-color="1"
                    />
                    <path
                      fill="#404041"
                      d="M99.84.79h-2.63L83.25 31.03h2.57L98.5 3.04l12.72 27.99h2.58L99.84.79z"
                      data-color="1"
                    />
                    <path
                      d="M124.27 20.57V31h-2.41V.79h13.66c13.52 0 13.52 19.78 0 19.78zm0-17.62v15.51h11.25c10.36 0 10.36-15.51 0-15.51z"
                      fill="#404041"
                      data-color="1"
                    />
                    <path
                      d="M154.6 31V.79h2.4V31z"
                      fill="#404041"
                      data-color="1"
                    />
                    <path
                      d="M176.63 2.91h-11.25V.83h24.9v2.08H179V31h-2.4z"
                      fill="#404041"
                      data-color="1"
                    />
                    <path
                      fill="#404041"
                      d="M208.05.79h-2.63l-13.96 30.24h2.57l12.68-27.99 12.72 27.99h2.58L208.05.79z"
                      data-color="1"
                    />
                    <path
                      d="M232.47.79v28H250V31h-19.93V.79z"
                      fill="#404041"
                      data-color="1"
                    />
                  </g>
                </svg>
              </Link>
            </div>
          </div>
        </TopLayout>
      </div>
    </div>
  )
}

export default WebPageIndex
