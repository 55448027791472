import React, { useEffect, useState } from "react"
import "./utilStyle.css"
import HeaderMemu from "./HeaderMenu"
import ChangeLanguageButton from "./ChangeLanguageButton"
import { useLocation } from "@reach/router"

type Props = {
  headerMenu: string[]
}

const TopHeader: React.FC<Props> = props => {
  const [isMobile, setIsMobile] = useState(false)
  const location = useLocation()
  const pathName = location.pathname

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1000)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [])
  return (
    <>
      {isMobile ? (
        <MobileHeader headerMenu={props.headerMenu} pathName={pathName} />
      ) : (
        <DesktopHeader headerMenu={props.headerMenu} pathName={pathName} />
      )}
    </>
  )
}

const DesktopHeader: React.FC<{
  headerMenu: string[]
  pathName: string
}> = ({ headerMenu, pathName }) => {
  return (
    <div className={"top-header-container"}>
      <div className={"header-wrapper"}>
        <div className="header-logo" />
        <div className={"header-menu-group"}>
          <HeaderMemu headerMenu={headerMenu} pathName={pathName} />
          <ChangeLanguageButton pathName={pathName} />
        </div>
      </div>
    </div>
  )
}

const MobileHeader: React.FC<{
  headerMenu: string[]
  pathName: string
}> = ({ headerMenu, pathName }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <HeaderMemu headerMenu={headerMenu} pathName={pathName} />
      <ChangeLanguageButton pathName={pathName} />
    </div>
  )
}

export default TopHeader
